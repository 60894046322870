<template>
  <div class="pa-2">
    <v-row align="end" justify="end">
      <v-col cols="12" sm="12" md="6" lg="4">
        <el-input
          suffix-icon="el-icon-search"
          v-model="search"
          clearable
          placeholder="Search"
        >
          <template slot="prepend">
            <el-button @click="createSupportingDocument()"
              ><i class="el-icon-plus"></i
            ></el-button>
          </template>
        </el-input>
      </v-col>
    </v-row>
    <v-row class="mt-3">
      <v-col cols="12">
        <v-data-table
          dense
          fixed-header
          :items="supportingDocuments"
          :search="search"
          :headers="headers"
          height="55vh"
          :footer-props="{'items-per-page-options':[25, 50, 100]}"
          style="cursor: pointer"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-row justify="center">
              <v-btn icon small @click="editItem(item)"
                ><v-icon small>edit</v-icon></v-btn
              >
              <v-btn icon small color="red" @click="deleteItem(item)"
                ><v-icon small>delete</v-icon></v-btn
              >
            </v-row>
          </template>

          
          <template v-slot:[`item.isFinancialDocument`]="{ item }">
          <v-icon v-if="item.isFinancialDocument" color="success">check</v-icon>
          </template>

          <template v-slot:[`item.labels`]="{ item }">
            <v-row justify="center" v-if="item.documentLabels.length<=3">
             <v-chip outlined class="mr-1" small label v-for="label in item.documentLabels" :key="label.id">{{label.name}}</v-chip>
            </v-row>
            <v-row justify="center" v-else>
             <v-chip outlined class="mr-1" small label v-for="label in item.documentLabels.slice(0, 3)" :key="label.id">{{label.name}}</v-chip>

             <span> + {{ item.documentLabels.length-3 }} others</span>
            </v-row>
          </template>

          <!-- <template v-slot:footer>
            <v-col cols="12" class="text-center my-0 py-0">
              <el-pagination
                style="color: var(--v-primaryText-base)"
                :current-page.sync="page"
                :page-size.sync="params.limit"
                :pager-count="5"
                :page-sizes="[18, 50, 100]"
                :layout="
                  !$vuetify.breakpoint.mobile
                    ? 'sizes, prev, pager, next, jumper, total'
                    : 'prev, pager, next'
                "
                :total="products.total"
              >
              </el-pagination>
            </v-col>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="modal"
      persistent
      width="600px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card v-if="item.documentLabels">
        <v-toolbar flat color="transparent">
          <v-toolbar-title>Manage Supporting Document</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="save()"
            :loading="savingItem"
            :disabled="!item.name"
            >Save</v-btn
          >
          <v-btn text @click="(modal = false), (item = {})">X</v-btn>
        </v-toolbar>
        <v-card-text class="py-3">
          <v-text-field
            outlined
            dense
            label="Name"
            v-model="item.name"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            label="Abbreviation Code"
            v-model="item.abbreviation"
          ></v-text-field>
          <v-checkbox
            outlined
            dense
            label="This is a financial document"
            v-model="item.isFinancialDocument"
          ></v-checkbox>
          
          <v-list>
            <v-subheader
              >Document Labels
              <v-btn icon color="primary" @click="createLabel()"
                ><v-icon>add_circle_outline</v-icon></v-btn
              ></v-subheader
            >
            <v-list-item
              v-for="(label, index) in item.documentLabels"
              :key="index"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ label.name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-row justify="center">
                  <v-btn icon small @click="editLabel(label, index)"
                    ><v-icon small>edit</v-icon></v-btn
                  >
                  <v-btn
                    icon
                    small
                    color="danger"
                    @click="removeLabel(label, index)"
                    ><v-icon small>delete</v-icon></v-btn
                  >
                </v-row>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="item.documentLabels.length == 0">
              <v-list-item-content>
                <span style="color: grey">No labels</span>
              </v-list-item-content></v-list-item
            >
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="labelModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title> Manage Label </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="(labelModal = false), (labelItem = {})">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-textarea
            label="Label"
            v-model="labelItem.name"
            auto-grow
            rows="2"
            outlined
            dense
            clearable
          ></v-textarea>
          <v-row justify="center" class="mt-2">
            <v-btn
              text
              color="primary"
              :disabled="!labelItem.name"
              @click="saveLabel()"
              >Submit</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card></v-dialog
    >
  </div>
</template>
<script>
export default {
  props: ["country", "supportingDocuments"],
  data: () => ({
    headers: [
      {
        text: "Action",
        value: "action",
        sortable: false,
        align: "center",
      },
      {
        text: "Abbreviation",
        value: "abbreviation",
        align: "left",
      },
      {
        text: "Financial Document",
        value: "isFinancialDocument",
        align: "center",
      },
      {
        text: "Name",
        value: "name",
        align: "left",
      },
      {
        text: "Labels",
        value: "labels",
        sortable: false,
        align: "center",
      },
    ],
    labelItem: {},
    labelModal: false,
    item: {},
    modal: false,
    savingItem: false,
    search: null,
    // supportingDocuments: []
  }),
  methods: {
    createLabel() {
      this.labelItem = {};
      this.labelModal = true;
    },
    createSupportingDocument() {
      this.item = {
        documentLabels: [],
      };
      this.modal = true;
    },
    deleteItem(item) {
      this.$confirm(
        "Are you sure you want to delete this supporting doc?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          if(item.id){
            await this.$API.updateSupportingDocument({
            id: item.id,
            isActive: false,
            isDeleted: true
          });
          }
          this.$emit("refresh");
          


          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "Delete canceled",
          // });
        });
    },
    editItem(item) {
      this.item = item;
      this.modal = true;
    },
    editLabel(item, index) {
      this.labelItem = item;
      this.labelItem.index = index
      this.labelModal = true;
    },
    async save() {
      this.savingItem = true;
      if (this.country) {
        this.item.countryRegionId = this.country.id;
      } else if (this.region) {
        this.item.regionid = this.region.id;
      }
      if(this.item.id){
        await this.$API.updateSupportingDocument(this.item)
      } else {
       let result = await this.$API.createSupportingDocument(this.item)
       this.item = result
      }
      this.$emit('updated', this.item)
      this.savingItem = false;
      this.modal= false
      this.item={}
    },
    async removeLabel(item, index) {
      if(item.id){
        await this.$API.createDocumentLabel({id: item.id, isActive: false, isDeleted: true});
      }
      this.item.documentLabels.splice(index, 1);
    },
    async saveLabel() {
      if (!this.item.id) {
        if(this.labelItem.index >=0){
          this.item.documentLabels[this.labelItem.index] = this.labelItem
        } else {
          this.item.documentLabels.push(this.labelItem);
        }
      } else {
        if(this.labelItem.id){
        this.labelItem.supportingDocumentId = this.item.id;
        let result = await this.$API.createDocumentLabel(this.labelItem);
        if(this.labelItem.index){
          this.item.documentLabels[this.labelItem.index] = this.labelItem
        } else {
          this.item.documentLabels.push(result)
        }
      } else {
        this.labelItem.supportingDocumentId = this.item.id
        let result = await this.$API.createDocumentLabel(this.labelItem);
        if(this.labelItem.index){
          this.item.documentLabels[this.labelItem.index] = this.labelItem
        } else {
          this.item.documentLabels.push(result)
        }
        // this.item.documentLabels.push(this.labelItem);
      }
      
    }
      this.labelModal = false;
      this.labelItem = {};
    },
  },
};
</script>
